import { palteEnum, carsTypeEnum } from '../constants/index';
import { checkOverWeight } from './components/utils';
import dayjs from 'dayjs';
import { isExpiredMonth, isExpiredDay } from '@/utils/validateTimeRanges';

const getPermitReg = () => {
  const permitFirst2Letters =
    'x\\d,X\\d,11,12,13,14,15,21,22,23,31,32,33,34,35,36,37,41,42,43,44,45,46,50,51,52,53,54,61,62,63,64,65,71,81,82,66';
  const str = permitFirst2Letters.split(',').join('|');
  return new RegExp(`^(${str})[0-9A-Za-z-]{9,11}$`);
};
export const transportPermitRegex = getPermitReg();
export const transportPermitValidMethod = val => {
  if (val) {
    return transportPermitRegex.test(val);
  }
  return true;
};

export const roadTransportNumberRegex = /^[a-zA-Z0-9]{2}\d{4,18}$/;
export const roadTransportValidMethod = (val, weight) => {
  const pattern = /0{6}$/;
  const totalWeight = Number(weight);
  if (val) {
    if (!roadTransportNumberRegex.test(val)) return false;
    if (totalWeight && !isNaN(totalWeight)) {
      if (totalWeight <= 4500 && !pattern.test(val)) {
        return false;
      }
      if (totalWeight > 4500 && pattern.test(val)) {
        return false;
      }
    }
  }
  return true;
};

export const getStep2FormData = dataRef => ({
  owner: {
    label: '车辆所有人',
    type: 'textarea',
    autosize: true,
    rules: [
      { required: true, message: '' },
      { pattern: /^[a-zA-Z0-9\u4e00-\u9fa5（）·【】「」]{1,50}$/, message: '非合法名称' }
    ]
  },
  useNature: {
    label: '使用性质',
    rules: [
      { required: true, message: '请输入使用性质' },
      { pattern: /^[a-zA-Z0-9\u4e00-\u9fa5]{1,20}$/, message: '1-20位数字字母或汉字' }
    ]
  },
  identifyCode: {
    label: '车辆识别代号',
    rules: [
      { required: true, message: '请输入车辆识别代码' },
      { partern: /[\dA-HJ-NPR-Z]{17}/, message: '车辆识别代号不合法' }
    ]
  },
  registerDate: {
    label: '行驶证注册日期',
    type: 'datePicker',
    visible: false,
    rules: [{ required: true, message: '请选择注册日期' }]
  },
  issueDate: {
    label: '行驶证发证日期',
    type: 'datePicker',
    visible: false,
    rules: [
      { required: true, message: '请选择发证日期' },
      {
        validator: () => {
          const { issueDate, registerDate } = dataRef.value;

          if (issueDate && registerDate) {
            const issueDateString = dayjs(issueDate).valueOf();
            const registerDateString = dayjs(registerDate).valueOf();
            if (registerDateString > issueDateString) return false;
          }
          return true;
        },
        message: '发证日期不早于注册日期'
      }
    ]
  },
  inspectionEndDate: {
    label: '检验有效期至',
    type: 'datePicker',
    visible: false,
    rules: [
      {
        validator: () => {
          const { inspectionEndDate } = dataRef.value;
          if (inspectionEndDate) {
            return !isExpiredMonth(inspectionEndDate);
          }
          return true;
        },
        message: '检验已过期'
      }
    ]
  },
  energyType: {
    label: '车辆能源类型',
    type: 'select',
    options: carsTypeEnum,
    visible: false,
    rules: [{ required: true, message: '请选择能源类型' }]
  },
  issueOrganization: {
    label: '发证机关',
    type: 'textarea',
    autosize: true,
    rules: [
      { required: true, message: '请输入发证机关' },
      {
        validator: item => {
          const rules = /^[\u4e00-\u9fa5]{5,50}$/;
          return rules.test(item);
        },
        message: '5-50位中文'
      }
    ]
  },

  fileNumber: {
    label: '档案编号',
    type: 'textarea',
    autosize: true,
    rules: [{ required: false, message: '' }]
  },
  outlineDimensionLength: {
    label: '外廓尺寸长',
    extra: 'mm',
    rules: [
      {
        validator: item => {
          if (!item) return true;
          if (isNaN(item)) return false;
          return item > 0 && item < 100000;
        },
        message: '1-100000内的数字'
      }
    ]
  },
  outlineDimensionWidth: {
    label: '外廓尺寸宽',
    extra: 'mm',
    rules: [
      {
        validator: item => {
          if (!item) return true;
          if (isNaN(item)) return false;
          return item > 0 && item < 100000;
        },
        message: '1-100000内的数字'
      }
    ]
  },
  outlineDimensionHeight: {
    label: '外廓尺寸高',
    extra: 'mm',
    rules: [
      {
        validator: item => {
          if (!item) return true;
          if (isNaN(item)) return false;
          return item > 0 && item < 100000;
        },
        message: '1-100000内的数字'
      }
    ]
  }
});
export const getStep1FormData = (dataRef, isReview, store) => {
  return {
    plateColor: {
      label: '车牌颜色',
      type: 'select',
      options: palteEnum,
      visible: false,
      rules: [{ required: true, message: '请输入车牌颜色' }]
    },
    truckTypeName: {
      label: '车辆类型',
      type: 'select',
      options: [],
      visible: false,
      rules: [{ required: true, message: '' }]
    },
    loadWeight: {
      label: '核定载质量',
      type: 'digit',
      extra: 'Kg',
      tips: false,
      rules: [
        { required: true, message: '请输入' },
        { validator: item => item > 100 && item < 60000, message: '不在可选范围' },
        {
          validator: () => {
            const { loadWeight, totalWeight } = dataRef.value;
            if (loadWeight && totalWeight && !isNaN(loadWeight) && !isNaN(totalWeight)) {
              if (loadWeight > +totalWeight * 0.95) return false;
            }
            return true;
          },
          message: '质量≤总质量 x 95%'
        }
      ]
    },
    totalWeight: {
      label: '总质量',
      type: 'digit',
      extra: 'Kg',
      tips: false,
      rules: [
        { required: true, message: '请输入' },
        { validator: item => item > 100 && item < 99000, message: '不在可选范围' }
      ]
    },
    curbWeight: {
      label: '整备质量',
      type: 'digit',
      extra: 'Kg',
      tips: false,
      name: 'curbWeight',
      rules: [
        { required: isReview, message: '请输入' },
        {
          validator: () => {
            const { loadWeight, totalWeight, curbWeight } = dataRef.value;
            if (curbWeight && loadWeight && totalWeight) {
              return Number(curbWeight) + Number(loadWeight) <= +totalWeight;
            }
            return true;
          },
          message: `整备质量+核定载质量(准牵引总质量)≤总质量`
        }
      ]
    },

    loadLength: {
      label: '载货长度(m)',
      type: 'custom',
      name: 'loadLength',
      hidden: true,
      rules: getLoadLengthRules(dataRef, store)
    }
  };
};
export const getLoadLengthRules = (dataRef, store) => {
  return [
    {
      validator: () => {
        const { loadLength } = dataRef.value;
        if (!loadLength && store.trailerConfig) return false;
        return true;
      },
      message: '请输入载货长度'
    },
    {
      validator: () => {
        const { truckTypeCode, loadLength } = dataRef.value;
        if (!loadLength) return true;
        const isRequired = truckTypeCode && checkTrailerBytype(truckTypeCode);
        const value = +loadLength;
        if (isRequired) {
          return !isNaN(value) && value >= 10 && value <= 99;
        }
        return true;
      },
      message: `10m≤载货长度≤99m`
    },
    {
      validator: () => {
        const { truckTypeCode, loadLength } = dataRef.value;
        if (!loadLength) return true;
        const isRequired = truckTypeCode && checkTrailerBytype(truckTypeCode);
        const value = +loadLength;
        if (!isRequired) {
          return !isNaN(value) && value >= 0.1 && value <= 99;
        }
        return true;
      },
      message: `0.1m≤载货长度≤99m`
    }
  ];
};
export const getStepTrailerFormData = (dataRef, store) => {
  return {
    trailerPlateNumber: {
      label: '挂车牌照号',
      type: 'select',
      hidden: false,
      visible: false,
      name: 'trailerPlateNumber',
      rules: [
        { required: true, message: '请输入' },
        {
          pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼]{1}[A-Z]{1}[A-Z0-9]{4,5}[挂超]{1}$/, // 和pc一致
          // pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学超警港澳]{1}$/,
          message: '挂车牌号不正确'
        }
      ]
    },
    trailerType: {
      label: '挂车类型',
      hidden: false,
      type: 'custom',
      name: 'trailerType',
      rules: [
        {
          validator: () => {
            const { trailerTypeCode } = dataRef.value;
            if (!trailerTypeCode && store.trailerConfig) return false;
            return true;
          },
          message: '请选择车辆类型'
        }
      ]
    },
    trailerLoadWeight: {
      label: '核定载质量(挂车)',
      type: 'custom',
      extra: 'Kg',
      tips: false,
      name: 'trailerLoadWeight',
      rules: [
        {
          validator: () => {
            const { trailerLoadWeight } = dataRef.value;
            if (!trailerLoadWeight && store.trailerConfig) return false;
            return true;
          },
          message: '请输入'
        },
        {
          validator: () => {
            const { trailerLoadWeight } = dataRef.value;
            if (!trailerLoadWeight) return true;
            const loadWeight = +trailerLoadWeight;
            if (isNaN(loadWeight) || loadWeight <= 100 || loadWeight >= 60000) {
              return false;
            }
            return true;
          },
          message: `100＜核定载质量＜60000`
        }
      ]
    },
    loadLength: {
      label: '载货长度(m)',
      type: 'custom',
      name: 'loadLength',
      rules: getLoadLengthRules(dataRef, store)
    }
  };
};

export const getTrailerStep1FormData = (dataRef, isReview) => {
  return {
    plateColor: {
      label: '车牌颜色',
      type: 'select',
      options: palteEnum,
      visible: false,
      rules: [{ required: true, message: '请输入车牌颜色' }]
    },
    truckTypeName: {
      label: '车辆类型',
      type: 'select',
      options: [],
      visible: false,
      rules: [{ required: true, message: '' }]
    },
    loadWeight: {
      label: '核定载质量',
      type: 'digit',
      extra: 'Kg',
      tips: false,
      rules: [
        { required: true, message: '请输入' },
        { validator: item => item > 100 && item < 60000, message: '不在可选范围' }
      ]
    },
    totalWeight: {
      label: '总质量',
      type: 'digit',
      extra: 'Kg',
      tips: false,
      rules: [
        { required: true, message: '请输入' },
        { validator: item => item > 100 && item < 99000, message: '不在可选范围' }
      ]
    },
    curbWeight: {
      label: '整备质量',
      type: 'digit',
      extra: 'Kg',
      tips: false,
      rules: [
        { required: isReview, message: '请输入' },
        {
          validator: () => {
            const { loadWeight, totalWeight, curbWeight } = dataRef.value;
            if (curbWeight && loadWeight && totalWeight) {
              return Number(curbWeight) + Number(loadWeight) <= +totalWeight;
            }
            return true;
          },
          message: `整备质量+核定载质量(准牵引总质量)≤总质量`
        }
      ]
    }
  };
};

export const getTruckStep3FormData = totalWeightRef => {
  const isOverWeight = checkOverWeight(totalWeightRef.value);
  return {
    businessName: {
      label: '业户名称',
      type: 'textarea',
      autosize: true,
      rules: [{ required: true, message: '请输入业户名称' }]
    },
    roadTransportNumber: {
      label: '道路运输证号',
      tips: isOverWeight
        ? ['前两位允许包含字母，其他仅能是数字', '总质量>4.5吨，尾号不能为000000']
        : ['前两位允许包含字母，其他仅能是数字', '总质量<=4.5吨，尾号为000000'],
      rules: [
        { required: true, message: '请输入道路运输证号' },
        {
          validator: item => {
            return roadTransportValidMethod(item, totalWeightRef.value);
          },
          message: '道路运输证号 格式错误'
        }
      ]
    },
    roadTransportPermitNumber: {
      label: '道路运输经营许可证号',
      tips: isOverWeight
        ? ['长度必须为11-13位，只需输入数字/字母部分']
        : ['长度必须为11-13位，只需输入数字/字母部分', '总质量<=4.5吨，可填车籍地前6位行政区划代码+000000'],
      rules: [
        { required: true, message: '请输入道路运输经营许可证号' },
        { validator: item => transportPermitValidMethod(item), message: '格式错误' }
      ]
    },
    validEndDate: {
      label: '运输证有效期至',
      type: 'datePicker',
      visible: false,
      rules: [
        { required: true, message: '请选择运输证有效期' },
        {
          validator: validEndDate => {
            if (validEndDate) {
              return !isExpiredDay(validEndDate);
            }
            return true;
          },
          message: '运输证检验已过期'
        }
      ]
    }
  };
};

export const carColorMap = [
  {
    key: '2',
    value: '黄牌',
    color: '#FFBD02'
  },
  {
    key: '1',
    value: '蓝牌',
    color: '#1464ff'
  },
  {
    key: '5',
    value: '绿牌',
    color: '#21b952'
  },
  {
    key: '93',
    value: '黄绿牌',
    color: ['#FFBD02', '#21b952'],
    mixinColor: true
  },
  {
    key: '3',
    value: '黑牌'
  },
  {
    key: '4',
    value: '白牌'
  },
  {
    key: '91',
    value: '农黄牌'
  },
  {
    key: '92',
    value: '农绿牌'
  },
  {
    key: '94',
    value: '渐变绿牌'
  },
  {
    key: '9',
    value: '其他'
  }
];

export const carsStateEnum = [
  {
    key: '1',
    value: '已提交'
  },
  {
    key: '2',
    value: '认证通过'
  },
  {
    key: '3',
    value: '认证失败'
  }
];

//行驶证字段
export const vehicleLicenseAllKeys = [
  'address',
  'carNumber',
  'vehicleType',
  'owner',
  'useCharacter',
  'model',
  'vin',
  'registerDate',
  'issueDate',
  'sealOrganize',
  'fileNo',
  'allowNum',
  'totalMass',
  'totalQuasiMass',
  'loadQuality',
  'curbWeight',
  'matchEnergyType',
  'vehicleHeight',
  'vehicleLength',
  'vehicleWidth',
  'externalSize'
];

//行驶证主页字段
export const vehicleLicenseMainKeys = [
  'address',
  'carNumber',
  'vehicleType',
  'owner',
  'useCharacter',
  'model',
  'vin',
  'registerDate',
  'issueDate',
  'sealOrganize'
];

//行驶证副业字段
export const vehicleLicenseSideKeys = [
  'fileNo',
  'allowNum',
  'totalMass',
  'totalQuasiMass',
  'loadQuality',
  'curbWeight',
  'matchEnergyType',
  'vehicleHeight',
  'vehicleLength',
  'vehicleWidth',
  'externalSize'
];

//道路运输证字段
export const transportLicenseKeys = [
  'areaCode',
  'businessCertificate',
  'businessScope',
  'issueDate',
  'licenseNumber',
  'maximumCapacity',
  'ownerAddress',
  'ownerName',
  'vehicleHeight',
  'vehicleHeight',
  'vehicleNumber',
  'vehicleType',
  'vehicleWidth'
];

export const checkTrailerBytype = truckType => {
  if (!truckType || typeof truckType !== 'string') return false;
  const typeList = ['Q11', 'Q12', 'Q21', 'Q22', 'Q31', 'Q32'];
  return typeList.includes(truckType);
};
